import { useEffect, useState } from "react";
import Articles from "./Articles";
import Contact from "./Contact";
import Features from "./Features";
import Footer from "./Footer";
import Hero from "./Hero";
import Media from "./Media";
import Navbar from "./Navbar";
import Testimonials from "./Testimonials";
import axios from "axios";

function App() {
  const [ethereum, setEthereum] = useState(null);
  const [claiming, setClaiming] = useState(false);

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask is installed!");
      setEthereum(window.ethereum);
    }
    if (ethereum) {
      ethereum.request({ method: "eth_requestAccounts" });
    }
  }, [ethereum]);

  const handleClaim = async () => {
    setClaiming(true);
    //  First we get the message to sign back from the server
    const messageToSign = await axios.get(`${process.env.REACT_APP_FUNCTION_URL}/verify`);
    console.log(messageToSign.data);
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const account = accounts[0];
    const signedData = await ethereum.request({
      method: "personal_sign",
      params: [
        JSON.stringify(messageToSign.data),
        account,
        messageToSign.data.id,
      ],
    });
    try {
      const res = await axios.post(`${process.env.REACT_APP_FUNCTION_URL}/verify`, {
        address: account,
        signature: signedData,
      });
      const url = res.data;
      setClaiming(false);
      window.open(url);
    } catch (error) {
      console.log(error);
      setClaiming(false);
      if (error.response && error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };
  return (
    <div>
      <div className="w-4/5 m-auto font-main pb-20 leading-relaxed">
        <Navbar />
        <Hero handleClaim={handleClaim} claiming={claiming} />
        <Media />
        <Features />
        <Testimonials />
        <Articles />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
