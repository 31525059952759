import mysite from "./assets/img/mysite.png";
import rightArrow from "./assets/img/arrowRight.png";
import submarine from "./assets/img/pinata.gif";
import sniping from "./assets/img/sniping.png";

const articles = [
  {
    id: 0,
    image: submarine,
    title: "How To Manage NFT Visibility",
    summary:
      "NFTs aren’t just digital pieces of art. They are layered mechanisms for proving ownership, access, rights, and more. NFT avatars...",
    link: "https://medium.com/pinata/how-to-manage-nft-visibility-18e9b7a76b8c",
  },
  {
    id: 1,
    image: sniping,
    title: "How To Prevent NFT Trait Sniping In Your PFP Project",
    summary:
      "NFTs have hit their stride this year. One of the biggest drivers for the mainstream adoption has been PFP (profile picture) projects.",
    link: "https://medium.com/pinata/how-to-prevent-nft-trait-sniping-in-your-pfp-project-506f17ff07d6",
  },
  {
    id: 2,
    image: mysite,
    title: "Host Your OpenSea NFT Collection On Squarespace With Pinata",
    summary:
      "The beautiful thing about NFTs is the way that they have pushed the creator economy forward.",
  },
];

const Articles = () => {
  return (
    <div className="mt-40 font-main">
      <h1 className="text-center font-bold text-3xl">
        Check out some of our articles
      </h1>
      <div>
        <div className="mt-10 w-20 m-auto border border-black border-b-1" />
      </div>
      <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-6">
        {articles.map((a) => {
          return (
            <div
              key={a.id}
              className="p-4 min-h-30 shadow-2xl border rounded-md w-100 m-auto text-left"
            >
              <img src={a.image} className="w-full mt-6 m-auto" alt={`header for article titled ${a.title}`} />
              <h2 className="mt-4 font-main font-bold text-xl">{a.title}</h2>
              <p className="mt-4 text-muted text-md">{a.summary}</p>
              <a
                className="mt-4 text-pinata-purple flex flew-row"
                href={a.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more{" "}
                <img
                  className="w-4 h-2 ml-2 mt-2"
                  src={rightArrow}
                  alt="right arrow"
                />
              </a>
            </div>
          );
        })}
      </div>
      <div className="text-center mt-20">
        <a
          href="https://medium.com/pinata"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="h-16 md:w-3/12 w-3/5 text-pinata-purple border border-pinata-purple font-mullish rounded-full">
            View all
          </button>
        </a>
      </div>
    </div>
  );
};

export default Articles;
