import React from "react";
import logo from "./assets/img/pinnielogo.png";

const links = [
  {
    name: "Home",
    href: "https://pinata.cloud",
  },
  {
    name: "App",
    href: "https://app.pinata.cloud",
  },
  {
    name: "Pricing",
    href: "https://pinata.cloud/pricing",
  },
  {
    name: "About",
    href: "https://pinata.cloud/about",
  },
];
const Navbar = () => {
  return (
    <nav className="font-mullish flex flex-row md:justify-between justify-center w-4/5 m-auto py-6">
      <img className="w-20" src={logo} alt="Pinata logo" />
      <div className="hidden md:block">
        {links.map((l) => {
          return (
            <a className="mx-8" href={l.href}>
              {l.name}
            </a>
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
