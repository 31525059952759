import React from "react";
import contact from "./assets/img/contact.png";
import instagram from "./assets/img/instagram.svg";
import medium from "./assets/img/medium.svg";
import linkedin from "./assets/img/linkedin.svg";
import twitter from "./assets/img/twitter.svg";
import slack from "./assets/img/slack.svg";

const contactPoints = [
  {
    id: 0,
    icon: twitter,
    link: "https://twitter.com/pinatacloud",
    alt: "Twitter link image",
  },
  {
    id: 1,
    icon: instagram,
    link: "http://instagram.com/pinata.cloud",
    alt: "Instagram link image",
  },
  {
    id: 2,
    icon: medium,
    link: "https://medium.com/pinata",
    alt: "Medium link image",
  },
  {
    id: 3,
    icon: linkedin,
    link: "https://www.linkedin.com/company/pinatacloud/",
    alt: "Linkedin link image",
  },
  {
    id: 4,
    icon: slack,
    link: "https://pinatahq.slack.com/join/shared_invite/zt-anb8lfxv-GVCxC59uTsjPAPRKk_H_pg#/shared-invite/email",
    alt: "Slack link image",
  },
];

const Contact = () => {
  return (
    <div className="mt-40 font-main md:-mb-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex flex-row md:justify-end justify-center">
          <img
            className="h-96 w-auto"
            src={contact}
            alt="Contacting Pinata"
          />
        </div>
        <div className="font-main flex flex-col justify-center align-center h-full md:w-full w-3/4 m-auto">
          <h1 className="text-2xl font-bold">Let’s keep in touch!</h1>
          <div className="flex flex-row">
            {contactPoints.map((c) => {
              return (
                <a
                  key={c.id}
                  href={c.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="h-10 w-10 px-2" alt={c.alt} src={c.icon} />
                </a>
              );
            })}
          </div>
          <div className="text-left mt-6">
            <a
              href="https://www.pinata.cloud/contact-us?utm_campaign=nftnyc&utm_medium=qr&utm_source=link&utm_content=lp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="h-16 md:w-3/12 w-3/5 text-pinata-purple border border-pinata-purple font-mullish rounded-full">
                Contact us
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
