import React from "react";
import illustration from "./assets/img/illustration.png";
const Hero = ({ handleClaim, claiming }) => {
  return (
    <div className="mt-20 w-4/5 m-auto font-main">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-10 flex justify-center">
        <div className="h-full flex flex-col justify-center align-center">
          <h1 className="text-4xl font-bold py-4">Hello, NYC!</h1>
          <div className="py-4 text-muted">
            <p>
              If you're at NFT.NYC, don't be a stranger! Stop by our table and
              say hello.
            </p>
            <p>
              Don't forget to claim your Pinata prize with your NFT.NYC NFT
              here.
            </p>
          </div>
          <div className="mt-10">
            <button onClick={handleClaim} className="h-16 w-1/2 text-white font-mullish rounded-full bg-pinata-purple">{claiming ? "Checking..." : "Claim my prize"}</button>
          </div>
        </div>
        <div className="col-span-2 flex flex-row md:justify-end justify-center">
          <img className="w-3/4" src={illustration} alt="NFT.NYC illustration" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
