import React from 'react'
import logo from "./assets/img/whitelogo.png";

const footerLinks = [
  {
    id: 0, 
    heading: "Pinata", 
    links: [
      {
        id:0, 
        name: "Home", 
        link: "https://pinata.cloud"
      }, 
      {
        id:1, 
        name: "Pricing", 
        link: "https://pinata.cloud/pricing"
      },
      {
        id:2, 
        name: "Sign up", 
        link: "https://app.pinata.cloud/register"
      }, 
      {
        id:3, 
        name: "Blog", 
        link: "https://www.pinata.cloud/blog"
      }, 
    ]
  },
  {
    id: 0, 
    heading: "Resources", 
    links: [
      {
        id:0, 
        name: "Documentation", 
        link: "https://docs.pinata.cloud"
      }, 
      {
        id:1, 
        name: "Community", 
        link: "https://cloud.us19.list-manage.com/track/click?u=f324dc339617e63f5135315d0&id=73f379a747&e=8c26e91aa4"
      },
      {
        id:2, 
        name: "Tech blog", 
        link: "https://medium.com/pinata"
      }, 
    ]
  },
  {
    id: 0, 
    heading: "About", 
    links: [
      {
        id:0, 
        name: "Who we are", 
        link: "https://www.pinata.cloud/about"
      }, 
      {
        id:1, 
        name: "Careers", 
        link: "https://angel.co/company/pinata-cloud"
      },
      {
        id:2, 
        name: "Contact us", 
        link: "https://www.pinata.cloud/contact-us"
      }, 
    ]
  },
]
const Footer = () => {
  return (
    <div className="min-h-20 bg-black w-screen pb-10">
    <div className="pt-20 font-main w-3/4 m-auto  grid grid-cols-1 md:grid-cols-2 gap-6 text-white">  
      <div>
        <img className="h-10" src={logo} alt={logo} />
        <p className="text-sm mt-10">©Copyright 2021 Pinata Technologies, Inc. All Rights Reserved.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 font-main">
        {
          footerLinks.map(l => {
            return (
              <div>
                <h3 className="text-xl font-bold">{l.heading}</h3>
                {
                  l.links.map(li => {
                    return (
                      <div><a className="text-muted my-2" href={li.link}>{li.name}</a></div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    </div>
    </div>
  )
}

export default Footer
