import { useState } from "react";
import leftArrow from "./assets/img/arrowLeft.png";
import rightArrow from "./assets/img/arrowRight.png";

const testimonials = [
  {
    id: 0,
    quote:
      "Omg. I just upgraded and went from going file by file to batching 500. Pinata is awesome!",
  },
  {
    id: 1,
    quote: "Damn y'all are awesome. Thx",
  },
  {
    id: 2,
    quote: "Everything from the documentation to the platform itself is very simple and does exactly what we need it to do each time.",
  },
];

const Testimonials = () => {
  const [selected, setSelected] = useState(0);
  return (
    <div>
      <div className="font-main text-white mt-40 w-3/4 m-auto rounded-lg bg-gradient-to-r from-light-purple to-pinata-purple p-12">
        <h1 className="text-4xl text-center font-bold">
          Creators & builders love Pinata
        </h1>
        <div>
          <div className="mt-10 w-20 m-auto border border-white border-b-1" />
        </div>
        <div className="pt-20 text-center text-white">
          <div>
            <p className="font-main text-3xl">"{testimonials[selected].quote}"</p>
          </div>
        </div>
      </div>
      <div className="mt-10 w-1/4 m-auto flex flex-row justify-between">
        <button disabled={selected === 0 ? true : false} onClick={() => setSelected(selected - 1)} className="outline-none border-none bg-none"><img className="w-8" src={leftArrow} alt="left arrow" /></button>
        <div className="flex flex-row justify-around">
          {
            testimonials.map(t => {
              return (
                <div key={t.id} className={t.id === selected ? 'h-4 w-4 bg-pinata-purple rounded-full mx-1' : 'h-4 w-4 bg-light-purple rounded-full mx-1'}>
                
                </div>
              )
            })
          }
        </div>
        <button disabled={selected === testimonials[testimonials.length - 1].id ? true : false} onClick={() => setSelected(selected + 1)} className="outline-none border-none bg-none"><img className="w-8" src={rightArrow} alt="right arrow" /></button>
      </div>
    </div>
  );
};

export default Testimonials;
