import React from "react";
import computer from "./assets/img/computer_illustration.png";

const Media = () => {
  return (
    <div className="font-main mt-40">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <img src={computer} alt="Computer illustration" />
        </div>
        <div className="flex flex-col h-full justify-center align-center">
          <h1 className="text-4xl font-bold">Your home for NFT media</h1>
          <div className="text-muted">
            <p className="mt-4">
              Pinata is the #1 trusted source for NFT media management that
              gives the power back to the creators and builders of the internet.
              If you can dream it, you can pin it. Pinata is here to help.
            </p>
            <ul className="mt-4 list-disc ml-4">
              <li>Manage NFT files</li>
              <li>Use a Dedicated Gateway</li>
              <li>Share with others</li>
            </ul>
          </div>
          <div className="text-center mt-10">
            <a href="https://pinata.cloud">
              <button className="h-16 md:w-3/12 w-3/5 text-pinata-purple border border-pinata-purple font-mullish rounded-full">
                Learn more
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
