import React from 'react'
import submarine from "./assets/img/submarine.png";
import rainbow from "./assets/img/Rainbow.png";
import backpack from "./assets/img/backpack.png";
import tools from "./assets/img/tools.png";
import celebrate from "./assets/img/celebrate.png";
import rocket from "./assets/img/Rocket.png";

const cards = [
  {
    icon: submarine, 
    heading: "Submarining NFTs", 
    content: "Hide any files from the public IPFS network with submarining, only available on Pinata. "
  }, 
  {
    icon: rainbow, 
    heading: "Dedicated Gateway", 
    content: "Your very own IPFS dedicated server. Add a custom domain to spruce it up. "
  }, 
  {
    icon: backpack, 
    heading: "Unlimited Storage", 
    content: "When it comes to storage, the limit does not exist. Unlimited starts at $0.15/gb. "
  }, 
  {
    icon: tools, 
    heading: "API Access", 
    content: "A must have for our builders and developers. We got you!"
  }, 
  {
    icon: celebrate, 
    heading: "Pinata Upload", 
    content: "Upload your files with a click of a button. Easy, so you can focus on your work, not technology. "
  }, 
  {
    icon: rocket, 
    heading: "Fast Global CDN", 
    content: "Whether its 1 NFT or 1 million, we'll help you get it out there in the blink of an eye. "
  }
]

const Features = () => {
  return (
    <div className="w-4/5 px-10 mt-40 m-auto">
      <h1 className="text-4xl text-main drop-shadow-2xl text-center font-bold">Top Features From Pinata Pro</h1>
      <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-6">
        {cards.map(c => {
          return (
            <div className="p-4 min-h-20 shadow-2xl border rounded-md w-100 m-auto text-center">
              <img src={c.icon}  className="h-10 mt-6 m-auto" alt={c.heading} />
              <h2 className="mt-4 font-main font-bold text-xl">{c.heading}</h2>
              <p className="mt-4 text-muted text-md">{c.content}</p>
            </div>
          )
        })}
      </div>
      <div className="text-center mt-20">
        <a href="https://pinata.cloud">
        <button className="h-16 md:w-3/12 w-3/5 text-pinata-purple border border-pinata-purple font-mullish rounded-full">Try Pinata now</button>
        </a>
      </div>
    </div>
  )
}

export default Features
